
const router_modular = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login/index'),
        meta: { title: '登录', keepAlive: false }
    },
    {
        path: '/infoSelect',
        name: 'infoSelect',
        component: () => import('@/views/InfoSelect'),
        meta: { title: '选择地址', keepAlive: false }
    },
    {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: { title: '主页', keepAlive: false }
    },
    {
        path: '/detailsPage',
        name: 'detailsPage',
        component: () => import('@/views/Home/detailsPage'),
        meta: { title: '详情页', keepAlive: false }
    },
    {
        path: '/shopPing',
        name: 'shopPing',
        component: () => import('@/views/shopPing'),
        meta: { title: '商城', keepAlive: false }
    },
    {
        path: '/shopPingDetail',
        name: 'shopPingDetail',
        component: () => import('@/views/shopPing/shopPingDetail'),
        meta: { title: '商品详情', keepAlive: false }
    },
    {
        path: '/accountInfo',
        name: 'accountInfo',
        component: () => import('@/views/accountInfo'),
        meta: { title: '账户信息', keepAlive: false }
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/accountInfo/personalCenter'),
        meta: { title: '个人中心', keepAlive: false }
    },
    {
        path: '/shoppingCart',
        name: 'shoppingCart',
        component: () => import('@/views/shoppingCart'),
        meta: { title: '我的购物车', keepAlive: false }
    },
    // 上下页面 相反
    {
        path: '/collect',
        name: 'collect',
        component: () => import('@/views/collect'),
        meta: { title: '我的收藏', keepAlive: false }
    },
    {
        path: '/orderGoods',
        name: 'orderGoods',
        component: () => import('@/views/orderGoods'),
        meta: { title: '我的订单', keepAlive: false }
    },
    {
        path: '/orderGoods-detail',
        name: 'orderGoods-detail',
        component: () => import('@/views/orderGoods/detail'),
        meta: { title: '订单详情', keepAlive: false }
    },
    {
        path: '/footmark',
        name: 'footmark',
        component: () => import('@/views/footmark'),
        meta: { title: '我的足迹', keepAlive: false }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: () => import('@/views/coupon'),
        meta: { title: '优惠券', keepAlive: false }
    },
    {
        path: '/orderPayment',
        name: 'orderPayment',
        component: () => import('@/views/orderPayment'),
        meta: { title: '订单支付', keepAlive: false }
    },
    {
        path: '/contrast',
        name: 'contrast',
        component: () => import('@/views/contrast'),
        meta: { title: '开始对比', keepAlive: false }
    },
    {
        path: '/svgParkingLot',
        name: 'svgParkingLot',
        component: () => import('@/views/svgParkingLot'),
        meta: { title: '车位系统', keepAlive: false }
    },
    {
        path: '/PrivacyPolicy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/PrivacyPolicy'),
        meta: { title: '隐私条款', keepAlive: false }
    },
    {
        path: '/addAccount',
        name: 'addAccount',
        component: () => import('@/views/addAccount'),
        meta: { title: '添加账户', keepAlive: false }
    },
    {
        path: '/ListAccount',
        name: 'ListAccount',
        component: () => import('@/views/addAccount/list'),
        meta: { title: '账户列表', keepAlive: false }
    },

    {
        path: '/eareastPay',
        name: 'eareastPay',
        component: () => import('@/views/Home/eareastPay'),
        meta: { title: '意向金', keepAlive: false }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/HomeView.vue')
    }
]


export default router_modular
