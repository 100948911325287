import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
import store from '../store/store_modular/index'

import traceAll from '@/common/Trace'

Vue.use(VueRouter)

const routes = [
  ...router_modular
]

const router = new VueRouter({
  routes,
  mode:'history',
  // base:'/parking/wxPay/',
})
//解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
//全局路由守卫

let RemainTrace = undefined;
router.beforeEach((to, from, next) => {
  if (RemainTrace !== undefined) {
    traceAll.stopRemain(RemainTrace);
    RemainTrace = undefined;
  }
  if(to.meta) {
    RemainTrace = traceAll.startRemain(to.meta.title,`访问${to.meta.title}页面`);
  } 




  let token = localStorage.getItem('token')
  if (token) {
    if (to.path == '/') {
      next('/infoSelect')
    } else {
      next()
    }
  } else {
    if (to.path == '/') {
      next()
    } else {
      next({ path: '/' })
    }
  }

})
// router.afterEach((to, from) => {
//   console.log(to, from);
// })
export default router
