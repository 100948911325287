
export default {
  /**接口请求的基础路径 */
  baseUrl: {
    // dev: 'http://localhost:8080/', // 开发路径
    dev: 'http://192.168.8.182:8081/', //手机端
    // pro: 'http://14.29.220.58:8088/' // 生产路径
    // dev: 'http://211.159.163.224:21002/', 
    // dev:'https://pss2d.pss-sh.com/',
    

    // pro:'https://pss.dsinfosys.com.cn/',
    pro:'https://pss2d.pss-sh.com/',
    // pro:'https://www.dsinfosys.com.cn/',
  },
};


