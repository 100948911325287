import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import { Toast } from 'vant';
import qs from  'qs'  

// import VueTouch from 'vue-touch'
//破图指令
import real from '@/utils/realImg'
Vue.use(real)
//配全局属性配置，在任意组件内可以使用this.$qs获取qs对象 
// 引入 Vconsole
import Vconsole from 'vconsole'
// 所有环境均使用
// new Vconsole()

Vue.prototype.$qs = qs
Vue.use(Vant);
Vue.use(Toast);
// Vue.use(VueTouch, {name: 'v-touch'})
Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
