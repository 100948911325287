// 通用错误图片的处理方法
const real = {}
real.install = Vue => {
    Vue.directive('real-img', async function (el, binding) {
        let imgURL = binding.value;//获取图片地址
        let realURL = el.src;
        if (imgURL) {
            let exist = await imageIsExist(realURL);
            if (!exist) {
                el.setAttribute('src', imgURL);
            }
        }
    });

    /**
    * 检测图片是否存在
    * @param url
    */
    let imageIsExist = function (url) {
        return new Promise((resolve) => {
            var img = new Image();
            img.onload = function () {
                if (this.complete == true) {
                    resolve(true);
                    img = null;
                }
            }
            img.onerror = function () {
                resolve(false);
                img = null;
            }
            img.src = url;
        })
    };
}
export default real
