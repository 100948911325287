
import http from '../utils/http'

const trace = {
    // 新增埋点数据
    addUserTrace(params) {
        return http.post(`/parking/userTrace/addUserTrace`, params);
    },
}
export default trace

